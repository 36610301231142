import React, { useLayoutEffect, useRef, useState } from 'react';
import {
  Box,
  FormLabel,
  Grid,
  Pagination,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { BarChart } from '@mui/x-charts/BarChart';
import Container from 'components/Container';
import moment from 'moment';

const EntradasSaidas = ({ indicadores }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [tab, setTab] = useState(0);
  const [mov, setMov] = useState(true);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetWidth / 2);
  }, []);

  const chart = indicadores?.indicadores?.entradasSaidas;
  const rows = (
    indicadores?.indicadores?.historicoEntradasSaidas || []
  )?.filter((f) => Boolean(mov) === Boolean(f?.movimentacao));

  const DocumentoCell = ({ row = {} }) => {
    const lines = [
      {
        label: 'Nº do Documento',
        value: row?.documento,
      },
      {
        label: 'Data de Emissão',
        value:
          moment(row?.dtemissao).isValid() &&
          moment(row?.dtemissao).format('DD/MM/YYYY [às] HH:mm'),
      },
      {
        label: 'Entidade',
        value: row?.entidade,
      },
      {
        label: 'Local de Estoque',
        value: row?.local,
      },
    ];

    const title = () => {
      let str = '';
      if (row?.natureza === 'ESTOQUE') {
        str += row?.natureza || '';
        if (Boolean(row?.operacao)) {
          str += ` - ${row?.operacao || ''}`;
        }
      } else {
        str = row?.especie;
        if (Boolean(row?.natureza)) {
          str += ` DE ${row?.natureza}`;
        }
      }
      return str;
    };

    return (
      <Box flex={1}>
        <Typography fontWeight="bold" variant="body2" mb={1}>
          {title()}
        </Typography>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={2} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
      />
    );
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" alignItems="center">
          <Switch checked={mov} onChange={(_, v) => setMov(v)} />
          <FormLabel>Movimentadas</FormLabel>
        </Grid>
        <Grid item xs={12}>
          <Tabs variant="fullWidth" value={tab} onChange={(_, v) => setTab(v)}>
            <Tab value={0} label="Quantidade" />
            <Tab value={1} label="Valor" />
            <Tab value={2} label="Peso" />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Box ref={ref}>
            {tab === 0 && (
              <BarChart
                xAxis={[
                  {
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.label)
                      : [''],
                    scaleType: 'band',
                  },
                ]}
                series={[
                  {
                    color: '#28518A',
                    label: 'Entrada',
                    data: Boolean(chart?.length)
                      ? chart?.map(
                          (g) => g?.[mov ? 'EntradaMov' : 'Entrada']?.quantidade
                        )
                      : [0],
                  },
                  {
                    color: '#01005E',
                    label: 'Saída',
                    data: Boolean(chart?.length)
                      ? chart?.map(
                          (g) => g?.[mov ? 'SaidaMov' : 'Saida']?.quantidade
                        )
                      : [0],
                  },
                ]}
                width={width}
                height={height}
              />
            )}
            {tab === 1 && (
              <BarChart
                xAxis={[
                  {
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.label)
                      : [''],
                    scaleType: 'band',
                  },
                ]}
                series={[
                  {
                    color: '#28518A',
                    label: 'Entrada',
                    data: Boolean(chart?.length)
                      ? chart?.map(
                          (g) => g?.[mov ? 'EntradaMov' : 'Entrada']?.valor
                        )
                      : [0],
                  },
                  {
                    color: '#01005E',
                    label: 'Saída',
                    data: Boolean(chart?.length)
                      ? chart?.map(
                          (g) => g?.[mov ? 'SaidaMov' : 'Saida']?.valor
                        )
                      : [0],
                  },
                ]}
                width={width}
                height={height}
              />
            )}
            {tab === 2 && (
              <BarChart
                xAxis={[
                  {
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.label)
                      : [''],
                    scaleType: 'band',
                  },
                ]}
                series={[
                  {
                    color: '#28518A',
                    label: 'Entrada',
                    data: Boolean(chart?.length)
                      ? chart?.map(
                          (g) => g?.[mov ? 'EntradaMov' : 'Entrada']?.peso
                        )
                      : [0],
                  },
                  {
                    color: '#01005E',
                    label: 'Saída',
                    data: Boolean(chart?.length)
                      ? chart?.map((g) => g?.[mov ? 'SaidaMov' : 'Saida']?.peso)
                      : [0],
                  },
                ]}
                width={width}
                height={height}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={rows}
            columns={[
              {
                field: 'documento',
                headerName: 'Documento',
                width: 500,
                sortable: false,
                renderCell: (props) => <DocumentoCell {...props} />,
              },
              {
                field: 'quantidade',
                headerName: 'Quantidade',
                type: 'number',
                width: 150,
                sortable: false,
                valueGetter: ({ row }) =>
                  row?.quantidade && row?.unidade_medida
                    ? `${row?.quantidade} ${row?.unidade_medida}`
                    : row?.quantidade || 0,
              },
              {
                field: 'preco',
                headerName: 'Preço Unitário',
                type: 'number',
                width: 150,
                sortable: false,
                valueFormatter: ({ value }) =>
                  (value || 0)?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }),
              },
              {
                field: 'desconto',
                headerName: 'Desconto',
                type: 'number',
                width: 150,
                sortable: false,
                valueGetter: ({ row }) =>
                  `${(row?.desconto_vlr || 0)?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })} (${(row?.desconto1_pc || 0)?.toLocaleString('pt-br', {
                    maximumFractionDigits: 2,
                  })}%)`,
              },
              {
                field: 'subtotal',
                headerName: 'Subtotal',
                type: 'number',
                width: 150,
                sortable: false,
                valueGetter: ({ row }) =>
                  (
                    (row?.preco || 0) * (row?.quantidade || 0) +
                    (row?.desconto_vlr || 0)
                  )?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }),
              },
            ]}
            autoHeight
            pagination
            pageSize={10}
            density="compact"
            disableColumnMenu
            showCellRightBorder
            showColumnRightBorder
            disableSelectionOnClick
            localeText={{ noRowsLabel: 'Nenhum Registro.' }}
            keepNonExistentRowsSelected
            getRowHeight={() => 'auto'}
            getRowId={(row) => JSON?.stringify(row)}
            components={{
              Pagination: CustomPagination,
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default EntradasSaidas;
